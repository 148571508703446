import http from '@/store/http';

import { message } from 'ant-design-vue';

export const getToken = async () => {
  let token = '';

  if (import.meta.env.VITE_MODE === 'development') {
    token =
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwd2QiOiJmMDhiOTQxZmE3ZmVmMmQ4ZGMyNTIxMDlmMzM1ODU1MSIsImlzcyI6Inl1bmRheWluLmRhb3lpMzY1LmNvbSIsImF1ZCI6Inl1bmRheWluLmRhb3lpMzY1LmNvbSIsImlhdCI6MTcyOTQ5Mjk2NCwibmJmIjoxNzI5NDkyOTY0LCJleHAiOjE3MzIwODQ5NjQsImp0aSI6eyJpZCI6MSwidHlwZSI6ImFkbWluIn19.Xw7M7na2nonI-AaH21tya6zUAKdHWkfutCCTabXmICo';
  } else {
    token = await JsApp.bridge.Token();
  }
  return token;
};
export const getHost = async () => {
  let host = '';
  if (import.meta.env.VITE_MODE === 'development') {
    host = 'yundayin.daoyi365.com';
  } else {
    host = await JsApp.bridge.Host();
  }
  return host;
};

export const usePut = async (url, data) => {
  let token = getApiToken();
  if (!token) {
    // console.log('====>>jsapp', await JsApp.bridge.Token());

    //获取 .env 的环境变量 MODE
    token = await getToken();
    http.setAuthorization(`Bearer ${token}`);
  }

  let res = await http.request(url, 'PUT_JSON', data);
  if (res.code === 200) {
    return res.data;
  } else {
    message.error(res.msg, 2);
    return Promise.reject(res);
  }
};

export const usePost = async (url, data) => {
  let token = getApiToken();
  if (!token) {
    // console.log('====>>jsapp', await JsApp.bridge.Token());
    token = await getToken();
    http.setAuthorization(`Bearer ${token}`);
  }

  let res = await http.request(url, 'POST_JSON', data);
  if (res.code === 200) {
    return res.data;
  } else {
    message.error(res.msg, 2);
    return Promise.reject(res);
  }
};

export const useGet = async (url, data) => {
  let token = getApiToken();
  if (!token) {
    //console.log('====>>jsapp', await JsApp.bridge.Token());
    token = await getToken();

    http.setAuthorization(`Bearer ${token}`);
  }
  let res = await http.request(url, 'GET', data);
  if (res.code === 200) {
    return res.data;
  } else {
    message.error(res.msg, 2);
    return Promise.reject(res);
  }
};
export const getApiToken = () => {
  let btoken = http.getAuthorization();
  if (btoken) {
    return btoken.split(' ')[1];
  }

  return '';
};
